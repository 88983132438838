import JobTypeSelect, {
    JobTypeSelectProps,
} from "components/inputs/Select/JobTypeSelect"
import { multiSelectValidator } from "utils/validators"

const FIELD_NAME = "selectedJobTypes" as const

const SelectedJobTypesField = (props: Omit<JobTypeSelectProps, "isMulti">) => {
    return (
        <JobTypeSelect
            name={FIELD_NAME}
            label="What roles are you interested in?"
            placeholder="Select roles"
            isMulti={true}
            {...props}
        />
    )
}

SelectedJobTypesField.fieldName = FIELD_NAME
SelectedJobTypesField.validators = {
    required: {
        [FIELD_NAME]: multiSelectValidator()
            .required("Required")
            .min(1, "Required")
            .max(3, "Please select no more than 3"),
    },
}

export { SelectedJobTypesField }

import { useField } from "formik"
import * as Yup from "yup"
import { WorkStatusEnum } from "__generated__/globalTypes"
import styled from "@emotion/styled"
import { theme, Text, Box } from "@pallet-hq/hegel"
import UnstyledButton from "components/Buttons/UnstyledButton"

const FIELD_NAME = "workStatus" as const

type JobSearchStageFieldProps = {
    label?: string
}

const JobSearchStageField = (props: JobSearchStageFieldProps) => {
    const [, meta, helpers] = useField(FIELD_NAME)
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gridGap="small"
            padding="0px"
        >
            <Text variant="paragraphMedium">
                {props.label ?? "Job Search Stage"}
            </Text>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                padding="0px"
                gridGap="small"
            >
                <WorkStatusOption
                    type="button"
                    selected={meta.value === WorkStatusEnum.READY_TO_INTERVIEW}
                    onClick={() =>
                        helpers.setValue(WorkStatusEnum.READY_TO_INTERVIEW)
                    }
                >
                    <Text variant="headlineSmall">
                        {"I'm actively interviewing"}
                    </Text>
                    <Text variant="paragraphLarge">
                        {
                            "You're actively interviewing for multiple new opportunities right now."
                        }
                    </Text>
                </WorkStatusOption>
                <WorkStatusOption
                    type="button"
                    selected={meta.value === WorkStatusEnum.OPEN_TO_OFFERS}
                    onClick={() =>
                        helpers.setValue(WorkStatusEnum.OPEN_TO_OFFERS)
                    }
                >
                    <Text variant="headlineSmall">
                        {"I'm thinking about my next opportunity"}
                    </Text>
                    <Text variant="paragraphLarge">
                        {
                            "You're not interviewing, but you're open to hearing about cool opportunities."
                        }
                    </Text>
                </WorkStatusOption>
            </Box>
        </Box>
    )
}

JobSearchStageField.fieldName = FIELD_NAME
JobSearchStageField.validators = {
    required: {
        [FIELD_NAME]: Yup.string().required("Required"),
    },
}

export { JobSearchStageField }

const WorkStatusOption = styled(UnstyledButton)`
    display: flex;
    flex-direction: column;
    gap: ${theme.space.xsmall};
    cursor: pointer;
    border: 1px solid ${theme.colors.gray300};
    border-radius: ${theme.space.small};
    padding: ${theme.space.small} ${theme.space.medium};
    text-align: left;

    ${({ selected }) =>
        selected &&
        `
                 background: ${theme.colors.gold000};
    border-color: ${theme.colors.darkGold};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
  0px 3px 5px rgba(0, 0, 0, 0.04);
    `}

    ${({ selected }) =>
        !selected &&
        `
                 &:hover {
                     background: ${theme.colors.gray100};
                     border-color: ${theme.colors.gray300};
                 }
    `}
`

import CitySelect, {
    CitySelectProps,
} from "components/inputs/Select/CitySelect"
import { multiSelectValidator } from "utils/validators"

const FIELD_NAME = "selectedCities" as const

const SelectedCitiesField = (props: Omit<CitySelectProps, "isMulti">) => {
    return (
        <CitySelect
            name={FIELD_NAME}
            label="City Preferences"
            placeholder="Search for locations"
            isMulti={true}
            shouldCloseMenuOnScroll={true}
            {...props}
        />
    )
}

SelectedCitiesField.fieldName = FIELD_NAME
SelectedCitiesField.validators = {
    notRequired: {
        [FIELD_NAME]: multiSelectValidator().max(
            5,
            "Please select no more than 5"
        ),
    },
    required: {
        [FIELD_NAME]: multiSelectValidator()
            .required("Required")
            .min(1, "Required")
            .max(5, "Please select no more than 5"),
    },
}

export { SelectedCitiesField }

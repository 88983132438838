import { CheckboxField, CheckboxFieldProps } from "@pallet-hq/hegel"
import * as Yup from "yup"

const FIELD_NAME = "isOpenToRemote" as const

const OpenToRemoteField = (props: CheckboxFieldProps) => {
    return (
        <CheckboxField
            name={FIELD_NAME}
            label="I'm open to remote work"
            size="small"
            {...props}
        />
    )
}

OpenToRemoteField.fieldName = FIELD_NAME
OpenToRemoteField.validators = {
    required: {
        [FIELD_NAME]: Yup.boolean().required("Required"),
    },
}

export { OpenToRemoteField }

import CitySelect, {
    CitySelectProps,
} from "components/inputs/Select/CitySelect"
import { singleSelectValidator } from "utils/validators"

const FIELD_NAME = "currentCity" as const

const CurrentCityField = (props: Omit<CitySelectProps, "isMulti">) => {
    return (
        <CitySelect
            name={FIELD_NAME}
            label="Current Location"
            placeholder="Search for location"
            isMulti={false}
            shouldCloseMenuOnScroll
            closeMenuOnSelect
            {...props}
        />
    )
}

CurrentCityField.fieldName = FIELD_NAME
CurrentCityField.validators = {
    required: { [FIELD_NAME]: singleSelectValidator({ required: true }) },
}

export { CurrentCityField }

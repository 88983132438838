import { TextAreaField, TextAreaFieldProps } from "@pallet-hq/hegel"
import { reqMaxCharValidator } from "utils/validators"

const FIELD_NAME = "preferencesSummary" as const

const PreferencesSummaryField = (props: TextAreaFieldProps) => {
    return (
        <TextAreaField
            label="What are you looking for in your next role"
            name={FIELD_NAME}
            placeholder="What do you want to achieve in your next role?"
            rows={3}
            maxLength={500}
            {...props}
        />
    )
}

PreferencesSummaryField.fieldName = FIELD_NAME
PreferencesSummaryField.validators = {
    required: {
        [FIELD_NAME]: reqMaxCharValidator(500),
    },
}

export { PreferencesSummaryField }

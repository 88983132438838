import { MultiSelect, MultiSelectProps } from "@pallet-hq/hegel"
import { useQuery } from "@apollo/client"
import { INDUSTRIES_QUERY } from "queries"
import { IndustriesQuery } from "__generated__/IndustriesQuery"
import { getSelectOptions } from "components/inputs/Select/utils"

export const IndustrySelect = (props: MultiSelectProps) => {
    const { data } = useQuery<IndustriesQuery>(INDUSTRIES_QUERY)
    return (
        <MultiSelect
            options={getSelectOptions(data?.industries, "slug", "name")}
            isSearchable
            {...props}
        />
    )
}

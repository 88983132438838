import { useField } from "formik"
import * as Yup from "yup"
import styled from "@emotion/styled"
import { theme, Text, Box } from "@pallet-hq/hegel"
import UnstyledButton from "components/Buttons/UnstyledButton"

const FIELD_NAME = "requiresSponsorshipForUs" as const

type USRequiresSponsorshipFieldProps = {
    label?: string
}

const USRequiresSponsorshipField = (props: USRequiresSponsorshipFieldProps) => {
    const [, meta, helpers] = useField(FIELD_NAME)
    return (
        <Box display="flex" flexDirection="column" gridGap="xsmall">
            <Text variant="uiLarge600">
                {props.label ??
                    "Will you now or in the future require sponsorship to work within the U.S.?"}
            </Text>
            <Box
                display="flex"
                flexDirection="row"
                width="100%"
                gridGap="small"
            >
                <RadioOption
                    type="button"
                    selected={meta.value === true}
                    onClick={() => helpers.setValue(true)}
                >
                    <Text variant="uiLarge600" width="100%">
                        Yes
                    </Text>
                </RadioOption>
                <RadioOption
                    type="button"
                    selected={meta.value === false}
                    onClick={() => helpers.setValue(false)}
                >
                    <Text variant="uiLarge600" width="100%">
                        No
                    </Text>
                </RadioOption>
            </Box>
        </Box>
    )
}

USRequiresSponsorshipField.fieldName = FIELD_NAME
USRequiresSponsorshipField.validators = {
    required: {
        [FIELD_NAME]: Yup.boolean().required("Required"),
    },
}

export { USRequiresSponsorshipField }

const RadioOption = styled(UnstyledButton)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border: 1px solid ${theme.colors.gray300};
    border-radius: ${theme.space.small};
    padding: ${theme.space.medium};
    text-align: center;

    ${({ selected }) =>
        selected &&
        `
                      background: ${theme.colors.gold000};
        border-color: ${theme.colors.darkGold};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
      0px 3px 5px rgba(0, 0, 0, 0.04);
        `}

    ${({ selected }) =>
        !selected &&
        `
                    &:hover {
                        background: ${theme.colors.gray100};
                        border-color: ${theme.colors.gray300};
                    }
        `}
`

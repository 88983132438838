import { useQuery } from "@apollo/client"
import {
    MultiSelect,
    MultiSelectProps,
    SingleSelect,
    SingleSelectProps,
} from "@pallet-hq/hegel"
import { CompanySizeQuery } from "__generated__/CompanySizeQuery"
import { getSelectOptions } from "../utils"
import { COMPANY_SIZE_QUERY } from "./queries"

export type CompanySizeSelectProps = { isMulti?: boolean } & Omit<
    SingleSelectProps | MultiSelectProps,
    "options"
>

export const CompanySizeSelect = ({
    isMulti = false,
    ...props
}: CompanySizeSelectProps) => {
    const SelectComponent = isMulti ? MultiSelect : SingleSelect
    const { data } = useQuery<CompanySizeQuery>(COMPANY_SIZE_QUERY)

    return (
        <SelectComponent
            {...props}
            options={getSelectOptions(data?.companySizes, "slug", "name")}
            disabled={!data?.companySizes || props.disabled}
        />
    )
}

import { FileUploader, FileUploaderProps } from "@pallet-hq/hegel"
import * as Yup from "yup"

const FIELD_NAME = "csv" as const

const SourceUploadField = (props: FileUploaderProps) => {
    return <FileUploader name={FIELD_NAME} accept=".csv" {...props} />
}

SourceUploadField.fieldName = FIELD_NAME
SourceUploadField.validators = {
    required: {
        [FIELD_NAME]: Yup.mixed()
            .test(
                "fileSize",
                "Please use a smaller file (max 64MB).",
                file => !file || file.size <= 64 * 1000 * 1000
            )
            .test(
                "extension",
                "Please use a CSV file.",
                file =>
                    !file || file.name.split(".").pop().toLowerCase() === "csv"
            )
            .test(
                "csvContainsEmail",
                "Please make sure the file contains an 'email' column",
                async file => {
                    if (!file) return true
                    const fileReader = new FileReader()
                    fileReader.readAsText(file)
                    return await new Promise(resolve => {
                        fileReader.onload = () => {
                            const csvString = fileReader.result as string
                            resolve(
                                csvString
                                    .split("\n")[0]
                                    .trim()
                                    .split(",")
                                    .includes("email")
                            )
                        }
                    })
                }
            ),
    },
}

export { SourceUploadField }

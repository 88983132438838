import {
    CompanySizeSelect,
    CompanySizeSelectProps,
} from "components/inputs/Select/CompanySizeSelect"
import { multiSelectValidator } from "utils/validators"

const FIELD_NAME = "selectedCompanySizes" as const

const SelectedCompanySizesField = (
    props: Omit<CompanySizeSelectProps, "isMulti">
) => {
    return (
        <CompanySizeSelect
            name={FIELD_NAME}
            label="What sizes of companies are you interested in?"
            placeholder="Favorite company sizes"
            isMulti={true}
            shouldCloseMenuOnScroll
            closeMenuOnSelect
            {...props}
        />
    )
}

SelectedCompanySizesField.fieldName = FIELD_NAME
SelectedCompanySizesField.validators = {
    required: {
        [FIELD_NAME]: multiSelectValidator()
            .required("Required")
            .min(1, "Required")
            .max(5, "Please select no more than 5"),
    },
}

export { SelectedCompanySizesField }

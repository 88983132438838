import { TextInputField, TextInputFieldProps } from "@pallet-hq/hegel"
import { PasswordField } from "@pallet/components/fields/Password"
import * as Yup from "yup"

const FIELD_NAME = "confirmPassword" as const

const ConfirmPasswordField = (props: TextInputFieldProps) => {
    return (
        <TextInputField
            type="password"
            label="Confirm password"
            name={FIELD_NAME}
            placeholder="Confirm password"
            {...props}
        />
    )
}

ConfirmPasswordField.fieldName = FIELD_NAME
ConfirmPasswordField.validators = {
    required: {
        [FIELD_NAME]: Yup.string()
            .required("Required")
            .oneOf(
                [Yup.ref(PasswordField.fieldName)],
                "Password does not match."
            ),
    },
}

export { ConfirmPasswordField }

import { Text } from "@pallet-hq/hegel"
import { ReactNode, useEffect, useState } from "react"
import {
    LinkedinUrlField,
    CurrentCityField,
    SelectedCitiesField,
    OpenToRemoteField,
    SelectedIndustriesField,
    WorkStatusField,
} from "@pallet/components/fields"
import { Form, FormikProps } from "formik"
import { Option } from "@pallet-hq/hegel/lib/UIComponents/SelectModules/Types"
import { TalentFormBox } from "@pallet/features/onboardingL1/TalentFormBox"
import { TalentFormFieldsBox } from "@pallet/features/onboardingL1/TalentFormFieldsBox"

export type TalentPreferencesFormValues = {
    [LinkedinUrlField.fieldName]: string
    [CurrentCityField.fieldName]: Option | null
    [SelectedCitiesField.fieldName]: Option[]
    [OpenToRemoteField.fieldName]: boolean
    [SelectedIndustriesField.fieldName]: Option[]
    [WorkStatusField.fieldName]: Option | null
}

type TalentPreferencesFormProps = {
    formikProps: FormikProps<TalentPreferencesFormValues>
    submitButton: ReactNode
}

export const TalentPreferencesForm = (props: TalentPreferencesFormProps) => {
    const {
        values: { currentCity, selectedCities },
    } = props.formikProps
    const [included, setIncluded] = useState(
        !!(
            currentCity &&
            selectedCities.map(city => city.value).includes(currentCity.value)
        )
    )
    const { setFieldValue } = props.formikProps

    useEffect(() => {
        if (
            !included &&
            currentCity &&
            !selectedCities
                .map(city => city.value)
                .includes(currentCity.value) &&
            selectedCities.length === 0
        ) {
            setFieldValue(SelectedCitiesField.fieldName, [
                ...selectedCities,
                currentCity,
            ])
            setIncluded(true)
        }
    }, [included, currentCity, selectedCities, setFieldValue])

    return (
        <Form>
            <TalentFormBox>
                <Text variant="titleMedium">Setup Job Preferences</Text>
                <TalentFormFieldsBox>
                    <LinkedinUrlField width="100%" autoFocus />
                    <CurrentCityField width="100%" />
                    <SelectedCitiesField width="100%" />
                    <OpenToRemoteField />
                    <SelectedIndustriesField width="100%" />
                    <WorkStatusField label="Availability" />
                </TalentFormFieldsBox>
                {props.submitButton}
            </TalentFormBox>
        </Form>
    )
}

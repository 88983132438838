import { MultiSelectProps } from "@pallet-hq/hegel"
import { multiSelectValidator } from "utils/validators"
import { IndustrySelect } from "@pallet/components/inputs/IndustrySelect"

const FIELD_NAME = "selectedIndustries" as const

const SelectedIndustriesField = (props: MultiSelectProps) => {
    return (
        <IndustrySelect
            label="Industry Preferences"
            name={FIELD_NAME}
            placeholder="Search for industries"
            isSearchable={true}
            {...props}
        />
    )
}

SelectedIndustriesField.fieldName = FIELD_NAME
SelectedIndustriesField.validators = {
    required: {
        [FIELD_NAME]: multiSelectValidator()
            .required("Required")
            .min(1, "Required")
            .max(5, "Please select no more than 5"),
    },
}

export { SelectedIndustriesField }

import { TextInputField, TextInputFieldProps } from "@pallet-hq/hegel"
import * as Yup from "yup"

const FIELD_NAME = "password" as const

const PasswordField = (props: TextInputFieldProps) => {
    return (
        <TextInputField
            type="password"
            label="Password"
            name={FIELD_NAME}
            placeholder="Enter password"
            {...props}
        />
    )
}

PasswordField.fieldName = FIELD_NAME
PasswordField.validators = {
    required: {
        [FIELD_NAME]: Yup.string().required("Required").min(5, "Too short."),
    },
}

export { PasswordField }

import { TextInputField, TextInputFieldProps } from "@pallet-hq/hegel"
import * as Yup from "yup"
import { urlValidator } from "utils/sharedValidationSchemas"

const FIELD_NAME = "substackLink" as const

const SubstackLinkField = (props: TextInputFieldProps) => {
    return (
        <TextInputField
            placeholder="https://abc.substack.com/"
            type="url"
            name={FIELD_NAME}
            {...props}
        />
    )
}

SubstackLinkField.fieldName = FIELD_NAME
SubstackLinkField.validators = {
    required: {
        [FIELD_NAME]: Yup.string().test(
            // https://github.com/jquense/yup/issues/256#issuecomment-668637258
            "is-valid-substack-link",
            "Must be a valid Substack URL.",
            async value => {
                await Yup.object()
                    .shape({
                        [FIELD_NAME]: urlValidator(200)
                            .matches(/https.*/, {
                                message: "Must be an https link.",
                            })
                            .required("Required"),
                    })
                    .validate({ [FIELD_NAME]: value })

                const resp = await fetch("/api/test-substack-url?url=" + value)
                if (resp.status !== 200) return false
                return !!(await resp.json()).substackId
            }
        ),
    },
}

export { SubstackLinkField }

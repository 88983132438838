import * as Yup from "yup"
import { TextAreaField, TextAreaFieldProps } from "@pallet-hq/hegel"

const FIELD_NAME = "bio" as const

const BioField = (props: TextAreaFieldProps) => {
    return (
        <TextAreaField
            label="Bio"
            name={FIELD_NAME}
            placeholder="Tell us about yourself"
            rows={7}
            maxLength={500}
            {...props}
        />
    )
}

BioField.fieldName = FIELD_NAME
BioField.validators = {
    required: {
        [FIELD_NAME]: Yup.string()
            .required("Required")
            .max(500, "Bio must be at most 500 characters"),
    },
}

export { BioField }

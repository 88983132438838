import { FileUploader, FileUploaderProps } from "@pallet-hq/hegel"
import { imageValidator2 } from "utils/validators"
import { SUPPORTED_FORMATS } from "constants/images"

const FIELD_NAME = "profileImage" as const

const ProfilePhotoField = (props: FileUploaderProps) => {
    return (
        <FileUploader
            label="Add a profile photo"
            name={FIELD_NAME}
            accept={SUPPORTED_FORMATS.join(",")}
            canDelete={false}
            {...props}
        />
    )
}

ProfilePhotoField.fieldName = FIELD_NAME
ProfilePhotoField.validators = {
    notRequired: {
        [FIELD_NAME]: imageValidator2(),
    },
}

export { ProfilePhotoField }

import CompanySelect, {
    CompanySelectProps,
} from "components/inputs/Select/CompanySelect"
import { Banner, Box, theme } from "@pallet-hq/hegel"
import { FiInfo } from "react-icons/fi"
import styled from "@emotion/styled"
import { multiSelectValidator } from "utils/validators"

const FIELD_NAME = "hiddenCompanies" as const

const BlockedCompaniesField = (props: CompanySelectProps) => {
    return (
        <Box display="flex" flexDirection="column" gridGap="xsmall">
            <CompanySelect
                label="We will hide your profile from these companies"
                name={FIELD_NAME}
                placeholder="Search companies"
                {...props}
                isMulti={true}
            />
            <CustomBanner
                icon={FiInfo}
                label="By default, your profile is hidden from your current employer."
            />
        </Box>
    )
}

BlockedCompaniesField.fieldName = FIELD_NAME
BlockedCompaniesField.validators = {
    notRequired: {
        [FIELD_NAME]: multiSelectValidator(),
    },
}

export { BlockedCompaniesField }

const CustomBanner = styled(Banner)`
    background: ${theme.colors.gray100};

    div,
    svg {
        color: ${theme.colors.gray600};
        stroke: ${theme.colors.gray600};
    }
`

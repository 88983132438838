import { gql } from "@apollo/client"

export const COMPANY_SIZE_QUERY = gql`
    query CompanySizeQuery {
        companySizes {
            id
            name
            slug
        }
    }
`
